<template>
    <div class="container">
        <h1>{{ $t('companyOwners.heading') }}</h1>
        <p class="alert alert-primary text-center my-4" v-if="companyOwners.length === 0">{{ $t('companyOwners.noOwners') }}</p>
        <div
            v-if="companyOwners.length > 0"
            class="mb-5"
        >
            <person-list
                :people="companyOwners || []"
                @edit="onEditPerson"
                @delete="onDeletePerson"
            />
            <div
                v-if="!showForm"
                class="mt-4"
            >
                <b-btn
                    variant="primary"
                    @click.prevent="onAddNewPerson"
                >
                    <b-icon icon="plus" />{{ $t('companyOwners.addPerson') }}
                </b-btn>
            </div>
        </div>
        <div
            v-if="showForm"
            class="p-4 bg-white border-light rounded shadow"
        >
            <loader-overlay
                :loading="companyOwnersSaving"
            >
                <company-person-form
                    :defaults="editedCompanyOwner || {}"
                    :date-picker-lang="datePickerLang"
                    :show-validation="touchForm"
                    @submit="onSubmitCompanyOwner"
                    @cancel="onCancel"
                />
            </loader-overlay>
        </div>
    </div>
</template>

<script>
import ViewBase from '../ViewBase.vue'
import CompanyPersonForm from '../../../../Components/CompanyPersonForm.vue'
import PersonList from '../../../../Components/PersonList.vue'

export default {
    name: 'CompanyOwners',
    extends: ViewBase,
    components: {
        PersonList,
        CompanyPersonForm
    },
    data () {
        const localCompanyOwners = this.sessionData ? this.sessionData.companyPeople.filter((item) => item.executive) : []
        return {
            showForm: !(this.sessionData && Array.isArray(localCompanyOwners) && localCompanyOwners.length > 0),
            editedCompanyOwner: null,
            companyOwnersSaving: false,
            touchForm: false
        }
    },
    computed: {
        companyPeople () {
            return this.sessionData?.companyPeople ?? []
        },
        companyOwners () {
            return ((this.sessionData) ? this.sessionData.companyPeople.filter((item) => item.executive) : []) || []
        }
    },
    watch: {
        companyOwners (newValue, oldValue) {
            this.$nextTick(() => {
                if (oldValue.length > 0 && newValue.length === 0) {
                    this.showForm = true
                }
                if (oldValue.length < newValue.length) {
                    this.showForm = false
                }
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        if (this.editedCompanyOwner) {
            this.$store.commit('setCompanyPersonUploads', [])
        }
        next()
    },
    mounted () {
        this.$serverValidator.addHandler('companyOwners', this.onServerValidation)
        this.$store.commit('setFormData', { name: 'companyOwners', content: this.companyOwners })
    },
    beforeDestroy () {
        this.$serverValidator.removeHandler('companyOwners', this.onServerValidation)
        this.touchForm = false
    },
    methods: {
        async onServerValidation (isValid, personId) {
            if (!isValid) {
                this.$notify.error(this.$t('errors.someDataIsMissing'))
                const person = this.companyOwners.find(item => item._id === personId)
                this.onEditPerson(person || {})
                this.touchForm = true
            }
        },
        async onSubmitCompanyOwner (data) {
            this.companyOwnersSaving = true
            data.broker = true
            data.executive = true
            const api = this.$api.clientSessionCompanyPeople
            try {
                const apiCall = (id, data) => (id) ? api.update(id, data) : api.create(data)
                await apiCall(data._id || null, data)
                this.editedCompanyOwner = null
                this.showForm = false
                this.$store.commit('setCompanyPersonUploads', [])
                this.$store.commit('setDataHash', { name: 'companyPersonOld', hash: null })
                this.$store.commit('setDataHash', { name: 'companyPerson', hash: null })
                this.$emit('reload')
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.sessionUpdateFailed'))
            } finally {
                this.$nextTick(() => {
                    this.companyOwnersSaving = false
                })
            }
        },
        onAddNewPerson () {
            this.onCancel()
            this.showForm = true
        },
        onCancel () {
            if (this.companyOwners.length > 0) {
                this.showForm = false
            }
            this.editedCompanyOwner = null
            this.touchForm = false
            const savedUploads = this.companyPeople.map(item => item.uploads).flat()
            const storedUploads = this.$store.state.uploads.companyPerson || []
            for (const upload of storedUploads) {
                if (!savedUploads.find(item => item._id === upload.upload)) {
                    this.$api.clientSessionUploads.delete(upload.upload)
                }
            }
            this.$store.commit('setCompanyPersonUploads', [])
        },
        onEditPerson (person) {
            this.onCancel()
            this.$nextTick(() => {
                this.editedCompanyOwner = { ...person }
                this.showForm = true
            })
        },
        onDeletePerson (person) {
            this.companyOwnersSaving = true
            this.$api.clientSessionCompanyPeople.delete(person._id)
                .then(() => {
                    this.$emit('reload')
                }).catch((error) => {
                    console.error(error.message)
                }).finally(() => {
                    this.$nextTick(() => {
                        this.companyOwnersSaving = false
                    })
                })
        }
    }
}
</script>
